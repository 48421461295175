
import { defineComponent } from 'vue';
import VueMeta from '@/components/meta/VueMeta.vue';
import store from '@/store';
import UiButton from '@/components/clickables/UiButton.vue';
import CategoryService from '@/services/CategoryService';
import InventoryService from '@/services/InventoryService';
import Search from '@/components/search/Search.vue';
import PrimaryCategoryDropdown from '@/components/search/PrimaryCategoryDropdown.vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import UiSlider from '@/components/slider/UiSlider.vue';
import ATCRegister from '@/components/modals/ATCRegister.vue';

export default defineComponent({
  name: 'Inventory',
  data: () => ({
    isLoaded: false,
    isProcessing: false,
    categoryTypes: [],
    selectedFilters: [] as any,
    searchInput: '',
    searchTimeoutId: 0,
    primaryCategories: [],
    primaryCategoriesResult: [],
    drugs: [] as Array<any>,
    promotedDrugs: [],
    drugPage: 1,
    totalDrugs: 0,
    loadedFilters: [] as any,
    searchedFilters: [] as any,
    primarySearchInput: '',
  }),
  computed: {
    baseApiUrl() {
      return process.env.VUE_APP_BASE_API_URL;
    },
    computedFilterItems(): any {
      return this.searchInput ? this.searchedFilters : this.loadedFilters;
    },
    sortedSelectedFilters: {
      get(): any {
        const searchParams = new URLSearchParams(this.URLSearchParams);

        const sortedFilters = [] as any;

        searchParams.forEach((value: string) => {
          const filter = this.selectedFilters.find(
            (el: any) => el.id === value,
          );

          if (!filter) return;

          sortedFilters.push(filter);
        });

        return sortedFilters;
      },
      set(newValue: any) {
        this.selectedFilters = newValue;
      },
    },
    filters(): any {
      return this.selectedFilters?.filter((el: any) => el?.id);
    },
    isMobile(): boolean {
      return store.getters.isMobile;
    },
    showFilters: {
      get() {
        return store.getters.showFilters;
      },
      set(newValue: string) {
        store.commit('SET_SHOW_FILTERS', newValue);
      },
    },
    mode: {
      get(): any {
        return this.isMobile ? 'blocks' : store.getters.mode;
      },
      set(newValue: string) {
        store.commit('SET_MODE', newValue);
      },
    },
    showDrugCount(): boolean {
      return (this.searchInput || this.filters.length) && this.drugs.length;
    },
    showPromotedDrugs(): boolean {
      return (
        !this.searchInput
        && !this.filters.length
        && this.promotedDrugs.length > 0
      );
    },
    URLSearchParams: {
      get(): string {
        return store.getters.filters;
      },
      set(newValue: string) {
        store.commit('SET_FILTERS', newValue);
      },
    },
    categoryIdsQuery(): any {
      const searchParams = new URLSearchParams(this.URLSearchParams);

      return searchParams.getAll('category_ids');
    },
    companyIdQuery(): any {
      const searchParams = new URLSearchParams(this.URLSearchParams);

      return searchParams.get('company_id');
    },
    searchQuery(): any {
      const searchParams = new URLSearchParams(this.URLSearchParams);

      return searchParams.get('search');
    },
    metaInfo() {
      return {
        link: [{ rel: 'canonical', href: window.location.origin }],
      };
    },
  },
  components: {
    VueMeta,
    UiButton,
    Search,
    PrimaryCategoryDropdown,
    PreLoader,
    UiSlider,
    ATCRegister,
  },
  watch: {
    searchInput: {
      handler(val: any) {
        window.clearTimeout(this.searchTimeoutId);

        this.searchTimeoutId = window.setTimeout(async () => {
          const searchParams = new URLSearchParams(this.URLSearchParams);

          if (val) {
            searchParams.set('search', val);
          } else {
            searchParams.delete('search');
          }

          const qs = searchParams.toString();

          if (!qs) {
            this.applyDetectedSearchParams('');
            return;
          }

          this.applyDetectedSearchParams(`?${searchParams.toString()}`);
        }, 500);
      },
    },
    categoryIdsQuery: {
      async handler(val) {
        this.listCategoriesByIds(val);
      },
      deep: true,
    },
    companyIdQuery: {
      async handler(val) {
        if (!val) return;

        const response = await InventoryService.producer(val);

        if (!response) return;

        const producer = this.mapProducerFilter(response);

        this.selectedFilters = this.selectedFilters
          .filter((el: any) => el.type !== 'producer');

        this.selectedFilters.unshift(producer);
      },
      immediate: true,
    },
    searchQuery: {
      async handler(val) {
        try {
          if (val) {
            this.isProcessing = true;

            const params = {
              title: val,
              category_types: ['tag', 'primary', 'usage', 'activeingredient'],
              take: 30,
            };

            const categories = await CategoryService.search(params);
            const producers = await InventoryService.producerSearch({ title: val, take: 10 });

            const mappedCategories = categories.data.map((el: any) => this.mapCategoryFilter(el));
            const mappedProducers = producers.map((el: any) => this.mapProducerFilter(el));

            this.searchedFilters = [...mappedCategories, ...mappedProducers];
          }

          this.drugPage = 1;

          await this.searchDrugs();

          this.isProcessing = false;
        } catch (error) {
          this.isProcessing = false;
        }
      },
      immediate: true,
    },
    filters: {
      async handler() {
        try {
          this.isProcessing = true;

          this.drugPage = 1;

          await this.searchDrugs();

          this.isProcessing = false;
        } catch (error) {
          this.isProcessing = false;
        }
      },
      deep: true,
      immediate: true,
    },
    primarySearchInput(val) {
      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        const params = {
          title: val,
          category_types: ['primary'],
          take: 50,
        };

        const primaryCategoriesResult = await CategoryService.search(params);

        this.primaryCategoriesResult = primaryCategoriesResult.data.sort(
          (a: any, b: any) => a.details.order - b.details.order,
        );
      }, 300);
    },
  },
  async mounted() {
    window.addEventListener('scroll', this.loadMore);

    window.addEventListener('popstate', (event) => {
      let qs = event.state.key;

      if (!qs) qs = '';

      this.applyDetectedSearchParams(qs);
      this.searchInput = this.searchQuery;
    });

    if (window.history.state.key) {
      this.applyDetectedSearchParams(window.history.state.key);
    } else {
      const searchParams = new URLSearchParams(window.location.search);

      const qs = searchParams.toString();

      if (!qs) {
        if (this.URLSearchParams) {
          this.applyDetectedSearchParams(this.URLSearchParams);
        }
      } else {
        this.applyDetectedSearchParams(`?${qs}`);
      }
    }

    this.searchInput = this.searchQuery;

    try {
      const [
        categoryTypes,
        primaryCategories,
        tagCategories,
        usageCategories,
        activeingredientCategories,
        producers,
      ] = await Promise.all([
        CategoryService.typeList(),
        CategoryService.search({ category_types: ['primary'], take: 50 }),
        CategoryService.search({ category_types: ['tag'], take: 10 }),
        CategoryService.search({ category_types: ['usage'], take: 10 }),
        CategoryService.search({ category_types: ['activeingredient'], take: 10 }),
        InventoryService.producerList({ take: 10 }),
      ]);

      this.categoryTypes = categoryTypes;

      const sortedPrimaryCategories = primaryCategories.data
        .sort((a: any, b: any) => a.details.order - b.details.order);

      this.primaryCategories = sortedPrimaryCategories.map((el: any) => {
        const primary = this.mapCategoryFilter(el) as any;

        primary.details = el.details;

        return primary;
      });

      this.primaryCategoriesResult = this.primaryCategories;

      const categories = [
        ...sortedPrimaryCategories,
        ...tagCategories.data,
        ...usageCategories.data,
        ...activeingredientCategories.data,
      ];

      const mappedCategories = categories.map((el: any) => this.mapCategoryFilter(el));
      const mappedProducers = producers.map((el: any) => this.mapProducerFilter(el));

      this.loadedFilters = [...mappedCategories, ...mappedProducers];

      this.listCategoriesByIds(this.categoryIdsQuery);

      const promotedDrugs = await InventoryService.drugSearch({
        published: true,
        order_by: 'updated_at:desc',
        take: 12,
      });

      this.promotedDrugs = promotedDrugs.data.map((el: any) => this.transformDrugForEdit(el));

      this.isLoaded = true;
    } catch (error) {
      this.isLoaded = true;
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.loadMore);
  },
  methods: {
    openATCModal() {
      (this.$refs['atc-register'] as any).openModal();
    },
    applyDetectedSearchParams(params: string) {
      this.URLSearchParams = params;

      if (params === window.location.search) return;

      window.history.pushState({ key: params }, '', params || '/');
    },
    addQueryFilter(model?: any) {
      const searchParams = new URLSearchParams(this.URLSearchParams);

      if (model.type === 'producer') {
        searchParams.delete('company_id');
        searchParams.append('company_id', model.id);
      }

      if (model.type === 'entity_category') {
        searchParams.append('category_ids', model.id);
      }

      this.applyDetectedSearchParams(`?${searchParams.toString()}`);
    },
    removeQueryFilter(model?: any) {
      const searchParams = new URLSearchParams(this.URLSearchParams);

      if (model.type === 'producer') {
        searchParams.delete('company_id');

        if (!searchParams.toString()) {
          this.applyDetectedSearchParams('');
          return;
        }

        this.applyDetectedSearchParams(`?${searchParams.toString()}`);
      }

      if (model.type === 'entity_category') {
        let qs = searchParams.toString();

        qs = qs.replace(`&category_ids=${model.id}`, '');
        qs = qs.replace(`category_ids=${model.id}&`, '');
        qs = qs.replace(`category_ids=${model.id}`, '');

        if (!qs) {
          this.applyDetectedSearchParams('');
          return;
        }

        this.applyDetectedSearchParams(`?${qs}`);
      }
    },
    toggleQueryFilter(model?: any) {
      const searchParams = new URLSearchParams(this.URLSearchParams);

      const qs = searchParams.toString();

      if (qs.includes(`category_ids=${model.id}`)) {
        this.removeQueryFilter(model);
      } else {
        this.addQueryFilter(model);
      }
    },
    clearAllFilters() {
      this.applyDetectedSearchParams('');
      this.selectedFilters = [];
      this.searchInput = '';
    },
    updatePrimarySearchInput(val: any) {
      this.primarySearchInput = val?.target?.value;
    },
    mapCategoryFilter(model: any) {
      const type = this.categoryTypes.find(
        (el: any) => el.id === model.category_type_id,
      ) as any;

      const category = {
        id: model.id,
        title: model.title,
        sub_title: model.code,
        type: 'entity_category',
        type_id: model.category_type_id,
        type_title: this.$t(`${type?.code}Category`),
      };

      return category;
    },
    mapProducerFilter(model: any) {
      return {
        id: model.id,
        title: model.title,
        type: 'producer',
        type_title: 'Firma',
      };
    },
    isInViewPort(el: any) {
      if (!el) return false;

      const { top, bottom } = el?.getBoundingClientRect();
      const vHeight = window.innerHeight || document.documentElement.clientHeight;

      return (top > 0 || bottom > 0) && top < vHeight;
    },
    transformDrugForEdit(model: any) {
      let imageSrc = '';

      if (model.details.images.length) {
        const defaultImage = model.details.images.find((el: any) => el.default);
        const image = defaultImage?.path || model.details.images[0].path;

        if (image && image !== 'link') {
          imageSrc = `${this.baseApiUrl}/api/media/${image}/preset/thumbnail`;
        }
      }

      const activeingredients = model.entity_categories?.filter((el: any) => el.category_type_code === 'activeingredient');

      return {
        idSlug: model.slug || model.id,
        image_src: imageSrc,
        title: model.title,
        dosage: model.details?.dosage_form?.text,
        strength: model.details?.strength?.text,
        activeingredients: activeingredients?.map((el: any) => el.category_title)?.join(', '),
        producer: model.producer?.title,
        promoted: model.promoted,
      };
    },
    async loadMore() {
      if (!this.isInViewPort(this.$refs['load-more']) || this.isProcessing) return;
      if (!this.searchInput && !this.filters.length) return;
      if (this.totalDrugs < this.drugPage * 12) return;

      try {
        this.isProcessing = true;

        this.drugPage += 1;

        const entityCategories = this.filters.filter((el: any) => el.type === 'entity_category');
        const producer = this.filters.find((el: any) => el.type === 'producer');

        const params = {
          title: this.searchInput,
          entity_categories: entityCategories?.map((el: any) => el.id),
          producer_id: producer?.id,
          published: true,
          order_by: 'promoted:desc,title:asc,code:asc',
          take: 12,
          page: this.drugPage,
        };

        const drugs = await InventoryService.drugSearch(params);

        const transformedDrugs = drugs.data.map((el: any) => this.transformDrugForEdit(el));

        this.drugs.push(...transformedDrugs);

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
      }
    },
    async listCategoriesByIds(ids: any) {
      if (!ids?.[0]) {
        this.selectedFilters = this.selectedFilters
          .filter((el: any) => el.type !== 'entity_category');
        return;
      }

      if (!this.categoryTypes.length) {
        this.categoryTypes = await CategoryService.typeList({ take: 50 });
      }

      const response = await CategoryService.search({ category_ids: [...ids], take: 50 });

      const mappedCategories = response.data.map((el: any) => this.mapCategoryFilter(el));

      this.selectedFilters = this.selectedFilters
        ?.filter((el: any) => el.type !== 'entity_category');

      this.selectedFilters.push(...mappedCategories);
    },
    async searchDrugs() {
      if (!this.searchInput && !this.filters.length) {
        this.drugs = [];
        this.totalDrugs = 0;
        return;
      }

      const entityCategories = this.filters.filter((el: any) => el.type === 'entity_category');
      const producer = this.filters.find((el: any) => el.type === 'producer');

      const params = {
        title: this.searchInput,
        entity_categories: entityCategories?.map((el: any) => el.id),
        producer_id: producer?.id,
        published: true,
        order_by: 'promoted:desc,title:asc,code:asc',
        take: 12,
      };

      const drugs = await InventoryService.drugSearch(params);

      this.drugs = drugs.data.map((el: any) => this.transformDrugForEdit(el));
      this.totalDrugs = drugs.total;
    },
    isPrimaryActive(id: string) {
      return this.URLSearchParams.includes(`category_ids=${id}`);
    },
  },
});
