import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import AuthProcess from '@/views/AuthProcess.vue';
import AuthLogin from '@/views/AuthLogin.vue';
import Inventory from '@/views/Inventory.vue';
import Products from '@/views/Products/Products.vue';
import LanguageSelector from '@/views/LanguageSelector.vue';
import Categories from '@/views/Categories.vue';
import Settings from '@/views/Settings/Settings.vue';
import SmPC from '@/views/SmPC/SmPC.vue';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'Home',
    path: '/',
    component: Inventory,
    meta: {
      navigation: true,
    },
  },
  {
    name: 'Inventory',
    path: '/inventory',
    component: Inventory,
  },
  {
    name: 'ProductNew',
    path: '/products/new',
    component: () => import('@/views/Products/ProductNew.vue'),
    meta: {
      authorize: ['admin'],
    },
  },
  {
    name: 'ProductUpdated',
    path: '/products/updated',
    component: () => import('@/views/Products/ProductUpdated.vue'),
    meta: {
      authorize: ['admin'],
    },
  },
  {
    name: 'ProductDiscontinued',
    path: '/products/discontinued',
    component: () => import('@/views/Products/ProductDiscontinued.vue'),
    meta: {
      authorize: ['admin'],
    },
  },
  {
    name: 'ProductUnpublished',
    path: '/products/unpublished',
    component: () => import('@/views/Products/ProductUnpublished.vue'),
    meta: {
      authorize: ['admin'],
    },
  },
  {
    name: 'Products',
    path: '/product',
    component: Products,
    children: [
      {
        name: 'Product',
        path: ':drugId',
        component: () => import('@/views/Products/Product.vue'),
        props: true,
      },
      {
        name: 'ProductCreate',
        path: ':drugId/create',
        component: () => import('@/views/Products/ProductCreate.vue'),
        meta: {
          navigation: false,
          authorize: ['admin'],
        },
      },
      {
        name: 'ProductEdit',
        path: ':drugId/edit',
        component: () => import('@/views/Products/ProductEdit.vue'),
        meta: {
          navigation: false,
          authorize: ['admin', 'company-producer'],
        },
      },
      {
        name: 'ProductUpdate',
        path: ':drugId/update',
        component: () => import('@/views/Products/ProductUpdate.vue'),
        meta: {
          navigation: false,
          authorize: ['admin'],
        },
      },
    ],
  },
  {
    name: 'Company',
    path: '/company',
    component: () => import('@/views/Company/Company.vue'),
    children: [
      {
        name: 'CompanyCreate',
        path: '/company/create',
        component: () => import('@/views/Company/Create.vue'),
        meta: {
          navigation: false,
          authorize: ['admin'],
        },
      },
      {
        name: 'CompanyShow',
        path: '/company/:id',
        component: () => import('@/views/Company/Show.vue'),
      },
      {
        name: 'CompanyEdit',
        path: '/company/:id/edit',
        component: () => import('@/views/Company/Edit.vue'),
        meta: {
          navigation: false,
          authorize: ['admin', 'company-producer'],
        },
      },
    ],
  },
  {
    name: 'LanguageSelector',
    path: '/language-selector',
    component: LanguageSelector,
  },
  {
    name: 'AuthProcess',
    path: '/auth/process',
    component: AuthProcess,
  },
  {
    name: 'AuthLogin',
    path: '/auth/login',
    component: AuthLogin,
  },
  {
    name: 'Categories',
    path: '/tags',
    component: Categories,
    meta: {
      authorize: ['admin'],
    },
  },
  {
    name: 'Settings',
    path: '/settings',
    component: Settings,
    meta: {
      authorize: ['admin'],
    },
    children: [
      {
        name: 'GlobalHistory',
        path: 'global-history',
        component: () => import('@/views/Settings/GlobalHistory.vue'),
      },
      {
        name: 'ManageUsers',
        path: '',
        component: () => import('@/views/Settings/ManageUsers.vue'),
      },
      {
        name: 'CreateUser',
        path: 'create/user',
        component: () => import('@/views/Settings/CreateUser.vue'),
        meta: {
          navigation: false,
        },
      },
      {
        name: 'EditUser',
        path: 'edit/user/:id',
        component: () => import('@/views/Settings/EditUser.vue'),
        meta: {
          navigation: false,
        },
      },
      {
        name: 'PaymentOverview',
        path: 'payment-overview',
        component: () => import('@/views/Settings/PaymentOverview.vue'),
      },
    ],
  },
  {
    name: 'SmPC',
    path: '/smpc',
    component: SmPC,
    meta: {
      authorize: ['admin'],
    },
    children: [
      {
        name: 'ManageSmpcs',
        path: 'list',
        component: () => import('@/views/SmPC/ManageSmpcs.vue'),
      },
      {
        name: 'EditSmpc',
        path: ':id/edit',
        component: () => import('@/views/SmPC/EditSmpc.vue'),
        meta: {
          navigation: false,
        },
      },
    ],
  },
  {
    name: '404',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => ({ left: 0, top: 0 }),
});

router.beforeEach(async (to, from, next) => {
  const { navigation } = to.meta;
  const { authorize } = to.meta as any;
  const userRoles = store.getters.user?.roles?.map((role: any) => role.name);

  if (navigation === false) {
    store.commit('SET_MAIN_NAVIGATION', false);
    store.commit('SET_SUB_NAVIGATION', false);
  } else {
    store.commit('SET_MAIN_NAVIGATION', true);
    store.commit('SET_SUB_NAVIGATION', true);
  }

  if (authorize) {
    if (userRoles?.includes('super-admin') || authorize.some((r: any) => userRoles?.includes(r))) {
      next();
    } else {
      store.commit('SET_MAIN_NAVIGATION', true);
      store.commit('SET_SUB_NAVIGATION', true);
      next({ name: '404' });
    }
  }
  next();
});

router.afterEach(() => {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
});

export default router;
