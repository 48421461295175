
import { defineComponent } from 'vue';
import InventoryService from '@/services/InventoryService';

export default defineComponent({
  name: 'DrugList',
  props: {
    atcId: {
      type: String,
    },
  },
  data: () => ({
    drugs: [],
  }),
  methods: {
    openDrug(id: string) {
      const routeData = this.$router.resolve({ name: 'Product', params: { drugId: id } });

      window.open(routeData.href, '_blank');
    },
  },
  watch: {
    atcId: {
      immediate: true,
      async handler(val) {
        if (!val) return;

        try {
          const params = {
            entity_categories: [val],
            order_by: 'title:asc',
            take: 50,
          } as any;

          const response = await InventoryService.drugSearch(params);

          this.drugs = response.data
            .map((drug: any) => ({
              id: drug.slug || drug.id,
              title: drug.title,
              dosage_form: drug.details?.dosage_form?.text,
              strength: drug.details?.strength?.text,
            }));
        } catch (error: any) {
          if (
            error?.response?.status === 422
            || error?.response?.status === 404
            || error?.response?.status === 409
          ) {
            this.$toast.show(error.response.data?.error?.message, 3000);
          } else {
            this.$toast.show('Server error', 3000);
          }
        }
      },
    },
  },
});
