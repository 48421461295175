
import { defineComponent } from 'vue';
import Clickable from '@/components/clickables/Clickable.vue';
import { TYPE_SIMPLE, TYPE_CLASS } from './model';

export default defineComponent({
  name: 'UIButton',
  props: {
    type: {
      type: String,
      default: TYPE_SIMPLE,
    },
  },
  components: {
    Clickable,
  },
  computed: {
    buttonClass(): string {
      return TYPE_CLASS[this.type];
    },
  },
});
