import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clickable = _resolveComponent("clickable")!

  return (_openBlock(), _createBlock(_component_clickable, {
    tag: "button",
    class: _normalizeClass(_ctx.buttonClass)
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["class"]))
}