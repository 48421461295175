
import { fal } from '@fortawesome/pro-light-svg-icons';
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  defineComponent,
} from 'vue';

type BaseStyleProps = {
  backgroundColor: string,
  color: string,
}

export default defineComponent({
  name: 'Toast',
  props: {
    message: { type: String, required: true },
    backgroundColor: { type: String },
    color: { type: String },
    timeout: {
      type: Number,
      default: 2000,
      validate: (val: number) => val >= 0,
    },
    clickToClose: { type: Boolean, default: true },
    close: { type: Function, required: true },
  },
  data: () => ({
    show: false,
    innerTimeout: 0,
  }),
  computed: {
    style(): BaseStyleProps {
      return {
        backgroundColor: this.backgroundColor ? this.backgroundColor : 'white',
        color: this.color ? this.color : '#eb3d47',
      };
    },
  },
  mounted() {
    this.onClearTimeoutHandler();
    this.show = true;
    if (this.timeout > 0) {
      this.innerTimeout = window.setTimeout(() => {
        this.closeToast();
      }, this.timeout);
    }
  },
  unmounted() {
    this.onClearTimeoutHandler();
  },
  methods: {
    enter() {
      const element = this.$refs.toast as HTMLElement;
      window.setTimeout(() => {
        element.style.bottom = '10px';
      }, 300);
    },
    leave() {
      const element = this.$refs.toast as HTMLElement;
      element.style.bottom = '-70px';
    },
    closeToast() {
      this.leave();
      window.setTimeout(() => {
        this.close();
        this.show = false;
        this.onClearTimeoutHandler();
      }, 300);
    },
    onClearTimeoutHandler() {
      if (this.innerTimeout) {
        try {
          window.clearTimeout(this.innerTimeout);
        } catch (e) {
          console.error(e);
        }
      }
    },
    onCloseHandler() {
      if (this.clickToClose) {
        this.closeToast();
      }
    },
  },
});
