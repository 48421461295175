{
  "SearchBarInput": "Søg produkt, aktiv substans, anvendelsesområde, administrationsvej, firma",
  "ATCRegister": "ATC-Register",
  "LatestProducts": "Senest opdaterede produkter",
  "ProductNotFound": "Din søgning gav desværre intet resultat",
  "primaryCategory": "Dyr",
  "tagCategory": "Administrationsveje",
  "usageCategory": "Anvendelse",
  "atcCategory": "ATC",
  "activeingredientCategory": "Aktive substanser"
}
