
import { defineComponent } from 'vue';
import UiScroll from '@/components/form/UiScroll.vue';

export default defineComponent({
  name: 'UiCombobox',
  props: {
    modelValue: {
      type: Object || Array,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'title',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    input: '',
    focus: false,
  }),
  computed: {
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  components: {
    UiScroll,
  },
  watch: {
    input(val) {
      this.$emit('input', val);
    },
    focus(val) {
      if (!val) this.input = '';
    },
  },
  methods: {
    toggleItem(item: any) {
      const isSelected = this.model.some(
        (el: any) => el[this.itemValue] === item[this.itemValue],
      );

      if (isSelected) {
        this.model = this.model.filter(
          (el: any) => el[this.itemValue] !== item[this.itemValue],
        );
        this.$emit('item-remove', item);
        return;
      }

      this.model.push(item);
      this.$emit('item-add', item);
      this.input = '';
    },
    isSelected(id: string) {
      return this.model.some((el: any) => el[this.itemValue] === id);
    },
  },
});
