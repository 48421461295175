
import { defineComponent } from 'vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import store from '@/store';

export default defineComponent({
  name: 'AuthLogin',
  components: {
    PreLoader,
  },
  computed: {
    baseApiUrl() {
      return process.env.VUE_APP_BASE_API_URL;
    },
    clientId() {
      return process.env.VUE_APP_CLIENT_ID;
    },
    redirectUri() {
      return process.env.VUE_APP_REDIRECT_URI;
    },
    scope() {
      return process.env.VUE_APP_SCOPE;
    },
    token() {
      return store?.getters?.token;
    },
  },
  mounted() {
    this.authorize();
  },
  methods: {
    generateState(length: number) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      let str = '';

      for (let i = 0; i < length; i += 1) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return str;
    },
    async authorize() {
      if (this.token.length) {
        this.$router.push({ name: 'Home' });
        return;
      }

      const state = this.generateState(40);

      const requestUrl = `${this.baseApiUrl}/oauth/authorize?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&response_type=code&scope=${this.scope}&state=${state}`;

      window.location.href = requestUrl;
    },
  },
});
