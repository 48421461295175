import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6fc11560"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["is-opened"]
const _hoisted_2 = { for: "icon" }
const _hoisted_3 = ["selected", "onClick", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "dropdown",
    class: "dropdown",
    onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = true)),
    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpen = false)),
    "is-opened": _ctx.isOpen,
    tabindex: "0"
  }, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_font_awesome_icon, {
      name: "icon",
      class: "dropdown-icon",
      icon: ['fal', 'angle-down']
    }),
    _createElementVNode("div", {
      class: _normalizeClass(`dropdown-menu ${_ctx.variant}`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "menu-item",
          selected: _ctx.isSelected(item),
          onClick: ($event: any) => (_ctx.onChangeHandler(item)),
          title: item
        }, _toDisplayString(item), 9, _hoisted_3))
      }), 128))
    ], 2)
  ], 40, _hoisted_1))
}