
import { defineComponent } from 'vue';
import UiScroll from '@/components/form/UiScroll.vue';

export default defineComponent({
  name: 'ATCDropdown',
  props: {
    modelValue: {
      type: Object,
    },
    searchInput: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    focus: false,
  }),
  computed: {
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
    input: {
      get(): string {
        return this.searchInput;
      },
      set(val: string) {
        this.$emit('update:search-input', val);
      },
    },
    computedPlaceholder(): string {
      return this.model.title || this.placeholder;
    },
  },
  components: {
    UiScroll,
  },
  watch: {
    focus(val) {
      if (!val) this.input = '';
    },
  },
  methods: {
    selectItem(item: any) {
      if (this.model.id === item.id) {
        this.removeItem(item);
        return;
      }

      this.$emit('item-change', item, this.model);
      this.model = item;
      this.input = '';
    },
    removeItem(item: any) {
      this.$emit('item-remove', item);
      this.model = {};
    },
    isSelected(id: string) {
      return this.model.id === id;
    },
  },
});
