
import { defineComponent, PropType } from 'vue';
import { Tag } from './model';

export default defineComponent({
  name: 'Clickable',
  props: {
    tag: {
      type: String as PropType<Tag>,
      default: 'a',
    },
  },
  computed: {
    disabled(): string {
      return this.$attrs.disabled as string;
    },
  },
});
