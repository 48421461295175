import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_NavigationBar = _resolveComponent("NavigationBar")!
  const _component_SubNavigationBar = _resolveComponent("SubNavigationBar")!
  const _component_NavigationBarMobile = _resolveComponent("NavigationBarMobile")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Sponsors = _resolveComponent("Sponsors")!
  const _component_SponsorsMobile = _resolveComponent("SponsorsMobile")!
  const _component_UiFooter = _resolveComponent("UiFooter")!
  const _component_AppUpdateModal = _resolveComponent("AppUpdateModal")!
  const _component_VueMeta = _resolveComponent("VueMeta")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["app-container", { mobile: _ctx.isMobileView }])
    }, [
      _createElementVNode("header", null, [
        (_ctx.isMainNavigationVisible)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_NavigationBar),
              _createVNode(_component_SubNavigationBar)
            ], 64))
          : _createCommentVNode("", true),
        _createVNode(_component_NavigationBarMobile)
      ]),
      _createElementVNode("main", null, [
        _createVNode(_component_router_view)
      ]),
      _createElementVNode("footer", null, [
        _createVNode(_component_Sponsors),
        _createVNode(_component_SponsorsMobile),
        _createVNode(_component_UiFooter)
      ])
    ], 2),
    _createVNode(_component_AppUpdateModal),
    _createVNode(_component_VueMeta, { "meta-info": _ctx.metaInfo }, null, 8, ["meta-info"])
  ], 64))
}