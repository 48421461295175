import {
  User,
  UserResponse,
  UserSearchParams,
  ApiError,
  Status,
} from '@/models/auth';
import http from '../utils/http-client';

class AuthService {
  static async getUser(): Promise<User> {
    const response: User = await http.get('api/user');

    return response;
  }

  static async getUserById(id: string): Promise<User> {
    const response: User = await http.get(`api/users/${id}`);

    return response;
  }

  static async deleteUser(id: string): Promise<User | ApiError> {
    let response: User = {} as User;

    try {
      response = await http.delete(`api/users/${id}`);
    } catch (exception: any) {
      const status = exception?.response?.status;

      if (status !== Status.SUCCESS) {
        const error = exception.response?.data?.error;

        if (!error) {
          return {
            message: 'Unexpected error occured',
            status_code: Status.ERROR,
          };
        }

        return error;
      }
    }

    return response;
  }

  static async createUser(specs: any): Promise<User | ApiError> {
    let response: User = {} as User;

    try {
      response = await http.post('api/users', specs);
    } catch (exception: any) {
      const status = exception?.response?.status;

      if (status !== Status.SUCCESS) {
        const error = exception.response?.data?.error;

        if (!error) {
          return {
            message: 'Unexpected error occured',
            status_code: Status.ERROR,
          };
        }

        return error;
      }
    }

    return response;
  }

  static async editUser(id: any, specs?: any): Promise<User | ApiError> {
    let response: User = {} as User;

    try {
      response = await http.post(`api/users/${id}`, specs);
    } catch (exception: any) {
      const status = exception?.response?.status;

      if (status !== Status.SUCCESS) {
        const error = exception.response?.data?.error;

        if (!error) {
          return {
            message: 'Unexpected error occured',
            status_code: Status.ERROR,
          };
        }

        return error;
      }
    }

    return response;
  }

  static async listUsers(params: UserSearchParams): Promise<UserResponse> {
    const {
      data,
      total,
      per_page: perPage,
      current_page: currentPage,
    }: UserResponse = await http.get('/api/users', { params });

    return {
      data,
      total,
      per_page: perPage,
      current_page: currentPage,
    };
  }

  static async logout(): Promise<any> {
    const response = await http.get('/api/logout');

    return response;
  }
}

export default AuthService;
