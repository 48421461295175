
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UiSlider',
  props: {
    id: {
      type: String,
      required: true,
    },
    scrollToEnd: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sliderWidth: 0,
    sliderItemsWidth: 0,
    translateAmount: 100,
    translate: 0,
  }),
  computed: {
    transalteStyle(): string {
      return `transform: translateX(-${this.translate}px)`;
    },
    showPrev(): boolean {
      return this.translate > 0;
    },
    showNext(): boolean {
      return this.sliderItemsWidth > this.sliderWidth + this.translate;
    },
  },
  watch: {
    sliderItemsWidth: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;

        if (this.scrollToEnd) {
          this.translate = this.sliderItemsWidth - this.sliderWidth;
        } else {
          this.translate = 0;
        }
      },
    },
  },
  methods: {
    sliderPrev() {
      if (this.translate < this.translateAmount) {
        this.translate = 0;
      } else {
        this.translate -= this.translateAmount;
      }
    },
    sliderNext() {
      const fromEnd = this.sliderItemsWidth - (this.sliderWidth + this.translate);

      if (fromEnd < this.translateAmount) {
        this.translate += fromEnd;
      } else {
        this.translate += this.translateAmount;
      }
    },
  },
  mounted() {
    const slider = this.$refs['ui-slider'] as HTMLElement;

    const event = new Event(`${this.id}-update`);

    const observer = new (window as any).MutationObserver(() => dispatchEvent(event));

    const sliderItems = slider?.getElementsByClassName('slider-items')[0];

    observer.observe(sliderItems, { subtree: true, childList: true });

    const mutations = observer.takeRecords();

    if (mutations) dispatchEvent(event);

    const events = ['load', 'resize', `${this.id}-update`];

    events.forEach((type: any) => window.addEventListener(type, () => {
      this.sliderWidth = slider?.offsetWidth;
      this.sliderItemsWidth = sliderItems?.scrollWidth;
    }));

    this.$nextTick(() => dispatchEvent(event));
  },
});
