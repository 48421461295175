
import { defineComponent } from 'vue';
import store from '@/store';

export default defineComponent({
  name: 'UiOverlay',
  props: {
    modelValue: {
      type: Boolean,
    },
  },
  computed: {
    model: {
      get(): boolean {
        return this.modelValue;
      },
      set(val: boolean) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  watch: {
    model(val) {
      store.commit('SET_MODAL_OPEN', val);
    },
  },
  methods: {
    scrollTop() {
      const el = document.querySelector('.ui-overlay') as any;
      el.scroll({ top: 0, behavior: 'smooth' });
    },
  },
});
