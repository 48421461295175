
import { defineComponent } from 'vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import OAuthService from '@/services/OAuthService';
import { TokenParams } from '@/models/oauth';
import store from '@/store';

export default defineComponent({
  name: 'AuthProcess',
  components: {
    PreLoader,
  },
  computed: {
    clientId() {
      return process.env.VUE_APP_CLIENT_ID;
    },
    redirectUri() {
      return process.env.VUE_APP_REDIRECT_URI;
    },
    clientSecret() {
      return process.env.VUE_APP_CLIENT_SECRET;
    },
    code(): any {
      return this.$route.query.code;
    },
  },
  watch: {
    code: {
      immediate: true,
      async handler(val) {
        if (!val) return;

        const params: TokenParams = {
          client_id: Number(this.clientId),
          grant_type: 'authorization_code',
          client_secret: String(this.clientSecret),
          redirect_uri: this.redirectUri as string,
          code: val,
        };

        const oauthResponse = await OAuthService.token(params);

        store.commit('SET_TOKEN', oauthResponse.access_token);

        this.$router.push({ name: 'Home' }).catch();
      },
    },
  },
});
