
import { defineComponent } from 'vue';
import slugify from 'slugify';
import UiTextField from '@/components/form/UiTextField.vue';
import UiOverlay from '@/components/form/UiOverlay.vue';
import UiSearch from '@/components/form/UiSearch.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import CategoryService from '@/services/CategoryService';
import Dropdown from '@/components/search/Dropdown.vue';
import UiButton from '@/components/form/UiButton.vue';
import UiCombobox from '@/components/form/UiCombobox.vue';

export default defineComponent({
  name: 'Tags',
  components: {
    UiTextField,
    UiOverlay,
    UiSearch,
    Pagination,
    PreLoader,
    Dropdown,
    UiButton,
    UiCombobox,
  },
  data: () => ({
    isLoaded: false as boolean,
    isProcessing: false as boolean,
    selectedCategoryType: {} as any,
    categoryTypes: [] as any,
    categories: [] as any,
    categorySearch: '',
    size: 1 as number,
    total: 0 as number,
    currentPage: 1 as number,
    searchTimeoutId: 0,
    isEmpty: false,
    categoryModal: false,
    categoryModel: {
      title: '',
      type: {},
      item_count: 0,
    } as any,
    confirmationModal: false,
  }),
  watch: {
    async categorySearch(val) {
      if (!/\S/.test(val) && val !== '') {
        return;
      }

      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(() => {
        this.currentPage = 1;

        this.listCategories();
      }, 300);
    },
  },
  computed: {
    currentItems(): number {
      const currentTotal = 10 * this.currentPage;
      return currentTotal >= this.total ? this.total : currentTotal;
    },
    types(): any {
      return this.categoryTypes.map((el: any) => el.title);
    },
    canSave(): any {
      return this.categoryModel?.title && this.categoryModel?.type?.code && !this.isProcessing;
    },
    canDelete(): any {
      return this.categoryModel.id && this.categoryModel.item_count === 0 && !this.isProcessing;
    },
  },
  async mounted() {
    this.categoryTypes = await CategoryService.typeList({ group: 'search-term' });

    this.listCategories();
  },
  methods: {
    async listCategories() {
      try {
        this.isProcessing = true;

        const types = this.selectedCategoryType?.code
          ? [this.selectedCategoryType.code]
          : this.categoryTypes.map((el: any) => el.code);

        const params = {
          category_types: types,
          title: this.categorySearch,
          page: this.currentPage,
          take: 10,
        };

        const response = await CategoryService.search(params);

        this.categories = response.data;

        if (response.data.length === 0) {
          this.isEmpty = true;
        } else {
          this.size = Math.ceil(response.total / response.per_page);
          this.total = response.total;
          this.isEmpty = false;
        }

        this.isLoaded = true;
        this.isProcessing = false;
      } catch (error: any) {
        this.isLoaded = true;
        this.isProcessing = false;

        if (
          error?.response?.status === 422
          || error?.response?.status === 404
          || error?.response?.status === 409
        ) {
          this.$toast.show(error.response.data?.error?.message, 3000);
        } else {
          this.$toast.show('Server error', 3000);
        }
      }
    },
    async onTypeChange(type: string): Promise<void> {
      this.selectedCategoryType = this.categoryTypes.find((el: any) => el.title === type) as any;
      this.currentPage = 1;

      this.listCategories();
    },
    getCategoryTitle(categoryTypeId: string) {
      const selectedType = this.categoryTypes.find((el: any) => el.id === categoryTypeId);

      return selectedType?.title;
    },
    scrollBack(): void {
      const source = this.$refs['categories-list-anchor'] as HTMLElement;
      const top = source.offsetTop - 10;
      window.scroll({
        top,
        behavior: 'smooth',
      });
    },
    openCategoryModal() {
      this.categoryModal = true;
    },
    closeCategoryModal() {
      this.categoryModal = false;

      this.categoryModel = {
        title: '',
        type: {},
        item_count: 0,
      };
    },
    async createCategory() {
      try {
        this.isProcessing = true;

        slugify.extend({
          '+': '-',
          '-': '-',
          ',': '-',
          '.': '-',
          '>': '-',
          '<': '-',
          '&': '-',
          '/': '-',
        });

        const specs = {
          title: this.categoryModel.title,
          code: slugify(this.categoryModel.title,
            {
              replacement: '-',
              lower: true,
              strict: true,
            }),
          type: this.categoryModel.type.code,
        };

        await CategoryService.create(specs);

        this.$toast.show(`${this.categoryModel.title}: Tag created`, 3000);

        this.closeCategoryModal();
        this.listCategories();
      } catch (error: any) {
        this.isProcessing = false;

        if (
          error?.response?.status === 422
          || error?.response?.status === 404
          || error?.response?.status === 409
          || error?.response?.status === 412
        ) {
          this.$toast.show(error.response.data?.error?.message, 3000);
        } else {
          this.$toast.show('Server error', 3000);
        }
      }
    },
    editCategory(model: any) {
      const type = this.categoryTypes.find((el: any) => el.id === model.category_type_id);

      this.categoryModel = {
        id: model.id,
        title: model.title,
        type,
        item_count: model.item_count,
      };

      this.openCategoryModal();
    },
    async updateCategory() {
      try {
        this.isProcessing = true;

        const specs = {
          title: this.categoryModel.title,
        };

        await CategoryService.update(this.categoryModel.id, specs);

        this.$toast.show(`${this.categoryModel.title}: Tag updated`, 3000);

        this.closeCategoryModal();
        this.listCategories();
      } catch (error: any) {
        this.isProcessing = false;

        if (
          error?.response?.status === 422
          || error?.response?.status === 404
          || error?.response?.status === 409
        ) {
          this.$toast.show(error.response.data?.error?.message, 3000);
        } else {
          this.$toast.show('Server error', 3000);
        }
      }
    },
    async deleteCategory() {
      try {
        this.isProcessing = true;

        await CategoryService.delete(this.categoryModel.id);

        this.$toast.show(`${this.categoryModel.title}: Tag deleted`, 3000);

        this.confirmationModal = false;
        this.closeCategoryModal();
        this.listCategories();
      } catch (error: any) {
        this.isProcessing = false;

        if (
          error?.response?.status === 422
          || error?.response?.status === 404
          || error?.response?.status === 409
        ) {
          this.$toast.show(error.response.data?.error?.message, 3000);
        } else {
          this.$toast.show('Server error', 3000);
        }
      }
    },
  },
});
