import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faThLarge,
  faCircle,
  faInfoCircle as fasInfoCircle,
  faExclamationCircle,
  faGripVertical,
  faChevronLeft,
  faChevronRight,
  faExternalLinkAlt as faExternalLinkAltBold,
  faEye as fasEye,
  faEyeSlash,
  faEdit as fasEdit,
} from '@fortawesome/free-solid-svg-icons';
import {
  faUser,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faSearch,
  faEdit,
  faTag,
  faCog,
  faExternalLinkAlt,
  faTh,
  faList,
  faTimes as falTimes,
  faPlus,
  faLink,
  faBold,
  faUnderline,
  faItalic,
  faTrash,
  faTextWidth,
  faLineHeight,
  faLayerGroup,
  faPlusCircle,
  faFile,
  faSyringe,
  faCheck,
  faSlidersH,
  faBars,
} from '@fortawesome/pro-light-svg-icons';
import {
  faPaw,
  faCircle as farCircle,
  faEye,
  faDownload,
  faExclamationTriangle,
  faFile as faFileDefault,
  faFilePdf,
  faFileWord,
  faFileImage,
  faSearchMinus,
  faSearchPlus,
  faCamera,
  faCrop,
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  faUser,
  faThLarge,
  faCircle,
  fasInfoCircle,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faSearch,
  faPaw,
  farCircle,
  faEdit,
  fasEdit,
  faTag,
  faCog,
  faExternalLinkAlt,
  faExternalLinkAltBold,
  faTh,
  faList,
  falTimes,
  faEye,
  fasEye,
  faEyeSlash,
  faDownload,
  faSearchMinus,
  faSearchPlus,
  faCamera,
  faCrop,
  faLink,
  faBold,
  faUnderline,
  faItalic,
  faTrash,
  faTextWidth,
  faLineHeight,
  faLayerGroup,
  faPlus,
  faPlusCircle,
  faFile,
  faSyringe,
  faFileDefault,
  faFilePdf,
  faFileWord,
  faExclamationTriangle,
  faExclamationCircle,
  faCheck,
  faGripVertical,
  faFileImage,
  faInfoCircle,
  faChevronLeft,
  faChevronRight,
  faSlidersH,
  faBars,
);
