
import { defineComponent } from 'vue';
import UiScroll from '@/components/form/UiScroll.vue';

export default defineComponent({
  name: 'UiCombobox',
  props: {
    modelValue: {
      type: Object || Array,
    },
    searchInput: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'title',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    focus: false,
  }),
  computed: {
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
    input: {
      get(): string {
        return this.searchInput;
      },
      set(val: string) {
        this.$emit('update:search-input', val);
      },
    },
    computedPlaceholder(): string {
      return this.multiple ? this.placeholder : this.model[this.itemText] || this.placeholder;
    },
  },
  components: {
    UiScroll,
  },
  watch: {
    focus(val) {
      if (!val) this.input = '';
    },
  },
  methods: {
    selectItem(item: any) {
      if (!this.multiple) {
        if (this.model[this.itemValue] === item[this.itemValue]) {
          this.model = {};
          return;
        }

        this.model = item;
      }

      if (this.multiple) {
        const isSelected = this.model.some(
          (el: any) => el[this.itemValue] === item[this.itemValue],
        );

        if (isSelected) {
          this.model = this.model.filter(
            (el: any) => el[this.itemValue] !== item[this.itemValue],
          );
          return;
        }

        this.model.push(item);
      }

      this.input = '';
    },
    isSelected(id: string) {
      if (!this.multiple) {
        return this.model[this.itemValue] === id;
      }

      if (this.multiple) {
        return this.model.some((el: any) => el[this.itemValue] === id);
      }

      return false;
    },
  },
});
