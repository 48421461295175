import http from '../utils/http-client';
import { SearchParams } from '../models/inventory';

class InventoryService {
  static async drugSearch(params?: SearchParams): Promise<any> {
    const response = await http.get('/api/inventory/drug/search', { params });

    return response;
  }

  static async producerSearch(params?: SearchParams): Promise<any> {
    const response = await http.get('/api/inventory/producer/search', { params });

    return response.data;
  }

  static async producer(idCode: string): Promise<any> {
    const producer = await http.get(`/api/inventory/producer/show/${idCode}`);

    return producer;
  }

  static async producerCreate(specs?: any): Promise<any> {
    const response = await http.post('/api/inventory/producer/create', specs);

    return response;
  }

  static async producerUpdate(id: string, specs?: any): Promise<any> {
    const response = await http.post(`/api/inventory/producer/update/${id}`, specs);

    return response;
  }

  static async producerLimitedUpdate(id: string, specs?: any): Promise<any> {
    const response = await http.post(`/api/inventory/producer/limited_update/${id}`, specs);

    return response;
  }

  static async producerList(params?: any): Promise<any> {
    const response = await http.get('/api/inventory/producer/list', { params });

    return response;
  }

  static async producerDelete(id: string): Promise<any> {
    const response = await http.delete(`/api/inventory/producer/delete/${id}`);

    return response;
  }

  static async drugCreate(specs?: any): Promise<any> {
    const response = await http.post('/api/inventory/drug/create', specs);

    return response;
  }

  static async drugUpdate(id: string, specs?: any): Promise<any> {
    const response = await http.post(`/api/inventory/drug/update/${id}`, specs);

    return response;
  }

  static async drugLimitedUpdate(id: string, specs?: any): Promise<any> {
    const response = await http.post(`/api/inventory/drug/limited_update/${id}`, specs);

    return response;
  }

  static async drugDelete(id: string): Promise<any> {
    const response = await http.delete(`/api/inventory/drug/delete/${id}`);

    return response;
  }

  static async drug(id: string, params?: any): Promise<any> {
    const product = await http.get(`/api/inventory/drug/show/${id}`, { params });

    return product;
  }

  static async packageCreate(specs: any): Promise<any> {
    const response = await http.post('/api/inventory/package/create', specs);

    return response;
  }

  static async packageUpdate(id: string, specs: any): Promise<any> {
    const response = await http.post(`/api/inventory/package/update/${id}`, specs);

    return response;
  }
}

export default InventoryService;
