import { App, createApp } from 'vue';
import Toast from './Toast.vue';

type BaseProps = {
  message: string,
  backgroundColor?: string,
  color?: string,
  timeout?: number,
  clickToClose?: boolean,
  close: () => void
}

interface ToastModule {
  show: (message: string, timeout: number) => void;
  hide: () => void;
}

export default {
  install: (app: App) => {
    const APP: App = app;

    let container: HTMLElement | null;

    let toastApp: App;

    const close = () => {
      if (toastApp) toastApp.unmount();

      container = document.querySelector('#vinordic-toast');
      if (container) document.body.removeChild(container);
    };

    const toast: ToastModule = {
      show: (message: string, timeout: number) => {
        const props: BaseProps = { message, timeout, close };

        props.close();

        container = document.createElement('div');
        container.setAttribute('id', 'vinordic-toast');

        document.body.appendChild(container);
        toastApp = createApp(Toast, props);
        toastApp.mount(container);
      },
      hide: close,
    };

    APP.config.globalProperties.$toast = toast;
  },
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $toast: ToastModule;
  }
}
