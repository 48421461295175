
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Dropdown',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    variant: {
      type: String,
      default: 'bottom',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['option:selected'],
  data: () => ({
    isOpen: false as boolean,
    selectedOption: '' as string,
  }),
  methods: {
    isSelected(item: string): boolean {
      return item === this.selectedOption;
    },
    onChangeHandler(item: string): void {
      const target = this.$refs.dropdown as HTMLDivElement;
      target.blur();

      if (this.selectedOption === item) {
        this.selectedOption = '';
        this.$emit('option:selected', '');
      } else {
        this.selectedOption = item;
        this.$emit('option:selected', item);
      }
    },
  },
});
