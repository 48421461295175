export enum Roles {
  SUPER_ADMIN = 'super-admin',
  ADMIN = 'admin',
  COMPANY_PRODUCER = 'company-producer'
}

export type Role = {
  name: Roles
}

export type Company = {
  id: string,
  code: string,
  name: string,
  short_code: string
}

export interface User {
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  roles: Role[],
  companies: Company[],
  enabled: true
}

export interface UserResponse {
  data: User[];
  total: number;
  per_page: number;
  current_page: number;
}

export interface UserSearchParams {
  page?: number;
  search?: string;
  company_code?: string;
  take?: number;
}

export interface ApiError {
  message: string;
  status_code: number;
}

export enum Status {
  SUCCESS = 200,
  EMPTY = 204,
  ERROR = 400,
  API_ERROR = 422,
  SMPC_ERROR = 412,
}
