import { ATC } from '../models/category';
import http from '../utils/http-client';

class CategoryService {
  static async atcTree(params?: any): Promise<any> {
    const response = await http.get('/api/category/atc/tree', { params });

    return response;
  }

  static async typeList(params?: any): Promise<any> {
    const response = await http.get('/api/category/typelist', { params });

    return response;
  }

  static async create(specs?: any): Promise<any> {
    const response = await http.post('/api/category/create', specs);

    return response;
  }

  static async show(id: string): Promise<any> {
    const response = await http.get(`/api/category/show/${id}`);

    return response;
  }

  static async update(id: string, specs?: any): Promise<any> {
    const response = await http.post(`/api/category/update/${id}`, specs);

    return response;
  }

  static async delete(id: string): Promise<any> {
    const response = await http.delete(`/api/category/delete/${id}`);

    return response;
  }

  static async tag(params?: any): Promise<any> {
    const response = await http.get('/api/category/tag', { params });

    return response.data;
  }

  static async search(params?: any): Promise<any> {
    const response = await http.get('/api/category/search', { params });

    return response;
  }

  static async createTag(title: string): Promise<any> {
    const response = await http.post(`/api/category/createtag?title=${title}`);

    return response.data;
  }

  static async updateTag(id: string, title: string): Promise<any> {
    const response = await http.post(`/api/category/updatetag/${id}`, { title });

    return response.data;
  }

  static async primary(params?: any): Promise<any> {
    const primary = await http.get('/api/category/primary', { params });

    return primary.data;
  }

  static async activeIngredient(params?: any): Promise<any> {
    const response = await http.get('/api/category/activeingredient', { params });

    return response.data;
  }

  static async atcShow(id: string): Promise<any> {
    const response = await http.get(`/api/category/atc/show/${id}`);

    return response;
  }

  static async atc(id = ''): Promise<ATC[]> {
    const response: {data: ATC[]} = await http.get(`/api/category/atc/${id}?take=50`);

    return response.data;
  }

  static async atcList(params?: any): Promise<any> {
    const response = await http.get('/api/category/atc', { params });

    return response.data;
  }

  static async deliveryRegulation(params: any): Promise<any> {
    const response = await http.get('/api/category/deliveryregulation', { params });

    return response.data;
  }
}

export default CategoryService;
