import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-757b5060"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sponsors view-mobile" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "members" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member, i) => {
          return (_openBlock(), _createElementBlock("a", {
            key: i,
            href: member.footer_member_url,
            target: "_blank"
          }, [
            _createElementVNode("img", {
              src: member.footer_member_logo,
              alt: _ctx.footer_member_name
            }, null, 8, _hoisted_6)
          ], 8, _hoisted_5))
        }), 128))
      ])
    ])
  ]))
}