
import { defineComponent } from 'vue';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import OverlayScrollbars from 'overlayscrollbars';

export default defineComponent({
  name: 'UiScroll',
  data: () => ({
    scroll: null as any,
  }),
  mounted() {
    this.scroll = OverlayScrollbars(document.querySelectorAll('.ui-scroll'), {
      overflowBehavior: {
        x: 'scroll',
      },
      sizeAutoCapable: true,
    });
  },
  methods: {
    scrollTop() {
      this.scroll.scroll(0, 300);
    },
  },
});
