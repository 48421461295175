import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-260a8566"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "primary-category-dropdown-box" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "primary-category-dropdown-dropdown"
}
const _hoisted_4 = {
  key: 0,
  class: "no-result"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["onMousedown"]
const _hoisted_7 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_scroll = _resolveComponent("ui-scroll")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["primary-category-dropdown", { focus: _ctx.focus }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
        placeholder: _ctx.placeholder,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focus = true)),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focus = false)),
        class: "primary-category-dropdown-input"
      }, null, 40, _hoisted_2), [
        [_vModelText, _ctx.input]
      ])
    ]),
    (_ctx.focus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!_ctx.items.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Ingen resultat"))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_ui_scroll, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `item-${i}`,
                        class: _normalizeClass(["dropdown-item", { selected: _ctx.isSelected(item[_ctx.itemValue]) }]),
                        onMousedown: ($event: any) => (_ctx.toggleItem(item))
                      }, [
                        _renderSlot(_ctx.$slots, "item", {}, () => [
                          _createElementVNode("span", {
                            class: "title",
                            title: item[_ctx.itemText]
                          }, _toDisplayString(item[_ctx.itemText]), 9, _hoisted_7)
                        ], true)
                      ], 42, _hoisted_6))
                    }), 128))
                  ]),
                  _: 3
                })
              ]))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}