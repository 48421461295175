import { TokenParams, TokenResponse } from '@/models/oauth';
import http from '../utils/http-client';

class OAuthService {
  static async token(params: TokenParams): Promise<TokenResponse> {
    const response: TokenResponse = await http.post('/oauth/token', params);

    return response;
  }
}

export default OAuthService;
