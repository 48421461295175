import { createApp } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@/font-awesome-icons';
import store from '@/store/index';
import ToastPlugin from '@/components/toast/Toast.plugin';
import { createMetaManager, defaultConfig } from 'vue-meta';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import i18n from './i18n';

const metaManager = createMetaManager(false, {
  ...defaultConfig,
  meta: { tag: 'meta', nameless: true },
});

const app = createApp(App);

app.use(i18n)
  .use(router)
  .use(store)
  .use(ToastPlugin)
  .use(metaManager);

app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
