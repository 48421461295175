
import { defineComponent } from 'vue';
import Clickable from '@/components/clickables/Clickable.vue';
import { MenuLink } from '@/components/navigation/model';
import store from '@/store';
import RolePermissions from '@/mixins/RolePermissions';

export default defineComponent({
  name: 'SubNavigationBar',
  mixins: [RolePermissions],
  components: {
    Clickable,
  },
  data: () => ({
    menuLinks: [
      {
        id: 1,
        label: "SmPC's",
        icon: ['fal', 'file'],
        name: 'ManageSmpcs',
        children: [],
        open: false,
        in_development: false,
      },
      {
        id: 2,
        label: 'Products',
        icon: ['fal', 'syringe'],
        name: 'Products',
        children: [
          {
            label: 'New Products',
            name: 'ProductNew',
          },
          {
            label: 'Updated Products',
            name: 'ProductUpdated',
          },
          {
            label: 'Discontinued Products',
            name: 'ProductDiscontinued',
          },
          {
            label: 'Unpublished Products',
            name: 'ProductUnpublished',
          },
        ],
        open: false,
        in_development: false,
      },
      {
        id: 3,
        label: 'Add Item',
        icon: ['fal', 'plus-circle'],
        name: 'Company',
        children: [
          {
            label: 'Company',
            name: 'CompanyCreate',
          },
        ],
        open: false,
        in_development: false,
      },
      {
        id: 4,
        label: 'Tags',
        icon: ['fal', 'tag'],
        name: 'Categories',
        children: [],
        open: false,
        in_development: false,
      },
      {
        id: 5,
        label: 'Settings',
        icon: ['fal', 'cog'],
        name: 'Settings',
        children: [
          {
            label: 'Global history',
            name: 'GlobalHistory',
          },
          {
            label: 'Administrate users',
            name: 'ManageUsers',
          },
          {
            label: 'Payment overview',
            name: 'PaymentOverview',
          },
        ],
        open: false,
        in_development: false,
      },
    ] as MenuLink[],
  }),
  computed: {
    token() {
      return store?.getters?.token;
    },
    wpAdminUrl() {
      return process.env.VUE_APP_WP_ADMIN_URL;
    },
    userRoles(): any {
      return store.getters.user.roles.map((role: any) => role.name);
    },
    isSuperAdmin(): boolean {
      return this.userRoles.includes('super-admin');
    },
    routes(): any {
      return this.$router.options.routes;
    },
    currentRouteName(): any {
      return this.$route.name;
    },
  },
  methods: {
    isActive(route: any) {
      if (route.name === this.currentRouteName) {
        return true;
      }

      return route.children.some((el: any) => el.name === this.currentRouteName);
    },
    onClickHandler(link: any, index?: number) {
      if (link.in_development) {
        alert(`${link.label} Coming Soon`);
        return;
      }

      for (let i = 0; i < this.menuLinks.length; i += 1) {
        this.menuLinks[i].open = false;
      }

      if (link?.children?.length) {
        if (index) {
          this.menuLinks[index].open = true;
        }
        return;
      }

      this.$router.push({ name: link.name });
    },
    routePermissions(): any {
      const children = [] as any;

      this.routes.forEach((route: any) => {
        if (route?.children) {
          children.push(...route.children);
        }
      });

      return [...this.routes, ...children]
        .map((route: any) => ({
          name: route.name,
          roles: route.meta?.authorize || [],
        }));
    },
    hasAccess(routeName: string) {
      if (!routeName) return false;

      const route = this.routePermissions().find(
        (r: any) => r.name === routeName,
      );

      if (!route) return false;

      if (this.isSuperAdmin) return true;

      const { roles } = route.roles;

      if (!roles || !roles.length) return true;

      return roles.some((r: any) => this.userRoles.includes(r));
    },
  },
});
