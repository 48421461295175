
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UiTextField',
  props: {
    modelValue: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    setFocus() {
      this.$nextTick(() => {
        (this.$refs['ui-text-input'] as HTMLElement).focus();
      });
    },
  },
});
