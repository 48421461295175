
import { defineComponent } from 'vue';
import Clickable from '@/components/clickables/Clickable.vue';
import AuthService from '@/services/AuthService';
import store from '@/store';
import axios from 'axios';
import RolePermissions from '@/mixins/RolePermissions';

export default defineComponent({
  name: 'NavigationBar',
  mixins: [RolePermissions],
  components: {
    Clickable,
  },
  data: () => ({
    menuLinks: [],
    subMenu: false,
  }),
  computed: {
    baseApiUrl() {
      return process.env.VUE_APP_BASE_API_URL;
    },
    clientId() {
      return process.env.VUE_APP_CLIENT_ID;
    },
    redirectUri() {
      return process.env.VUE_APP_REDIRECT_URI;
    },
    scope() {
      return process.env.VUE_APP_SCOPE;
    },
    token() {
      return store?.getters?.token;
    },
    userId() {
      return store?.getters?.user?.id;
    },
  },
  methods: {
    editAccount() {
      this.subMenu = false;

      this.$router.push({
        name: 'EditUser',
        params: {
          id: this.userId,
        },
      });
    },
    onMouseOver() {
      this.subMenu = true;
    },
    onMouseLeave() {
      this.subMenu = false;
    },
    onClickProfileHandler() {
      if (!this.token.length) {
        this.authorize();
      }
    },
    async onLogOut() {
      try {
        await AuthService.logout();
      } finally {
        await store.dispatch('logout');

        this.subMenu = false;

        this.$router.push({ name: 'Home' });
      }
    },
    generateState(length: number) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      let str = '';

      for (let i = 0; i < length; i += 1) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return str;
    },
    async authorize() {
      const state = this.generateState(40);

      const requestUrl = `${this.baseApiUrl}/oauth/authorize?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&response_type=code&scope=${this.scope}&state=${state}`;

      window.location.href = requestUrl;
    },
  },
  async mounted() {
    const menuLinks = await axios({
      url: process.env.VUE_APP_WP_MENU_API_URL,
      method: 'GET',
      responseType: 'json',
    });

    this.menuLinks = menuLinks.data.items.sort((a: any, b: any) => a.menu_order - b.menu_order);
  },
});
