import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-32f25442"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "item-content" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = {
  key: 0,
  class: "caption"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drugs, (drug, i) => {
    return (_openBlock(), _createElementBlock("li", {
      key: `drug-item-${i}`,
      class: "section-item",
      onClick: ($event: any) => (_ctx.openDrug(drug.id))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(drug.title), 1),
        (drug.dosage_form || drug.strength)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (drug.dosage_form)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(drug.dosage_form) + " ", 1))
                : _createCommentVNode("", true),
              (drug.strength)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, "(" + _toDisplayString(drug.strength) + ")", 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_font_awesome_icon, {
        class: "item-icon",
        icon: ['fas', 'external-link-alt']
      })
    ], 8, _hoisted_1))
  }), 128))
}