import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, renderList as _renderList, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2da6794e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "atc-dropdown-box" }
const _hoisted_2 = ["placeholder", "disabled"]
const _hoisted_3 = {
  key: 1,
  class: "atc-dropdown-icon search"
}
const _hoisted_4 = {
  key: 0,
  class: "atc-dropdown-dropdown"
}
const _hoisted_5 = {
  key: 0,
  class: "no-result"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["onMousedown"]
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "code" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ui_scroll = _resolveComponent("ui-scroll")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["atc-dropdown", { focus: _ctx.focus, disabled: _ctx.disabled }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
        placeholder: _ctx.computedPlaceholder,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focus = true)),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focus = false)),
        class: "atc-dropdown-input",
        disabled: _ctx.disabled
      }, null, 40, _hoisted_2), [
        [_vModelText, _ctx.input]
      ]),
      (!_ctx.disabled)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.focus && _ctx.model.id)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "atc-dropdown-icon remove",
                  onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.removeItem(_ctx.model)), ["stop"]))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fal', 'times'] })
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fal', 'angle-down'] })
                ]))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    (_ctx.focus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (!_ctx.items.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Ingen resultat"))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_ui_scroll, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `item-${i}`,
                        class: _normalizeClass(["dropdown-item", { selected: _ctx.isSelected(item.id) }]),
                        onMousedown: ($event: any) => (_ctx.selectItem(item))
                      }, [
                        _createElementVNode("div", {
                          class: "title",
                          title: item.title
                        }, _toDisplayString(item.title), 9, _hoisted_8),
                        _createElementVNode("div", _hoisted_9, _toDisplayString(item.code), 1)
                      ], 42, _hoisted_7))
                    }), 128))
                  ]),
                  _: 1
                })
              ]))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}