
import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'SponsorsMobile',
  data: () => ({
    title: '',
    members: [],
  }),
  async mounted() {
    const response = await axios({
      url: process.env.VUE_APP_WP_FOOTER_API_URL,
      method: 'GET',
      responseType: 'json',
    });

    const { acf } = response.data;

    this.title = acf.memberslist_title;
    this.members = acf.footer_members;
  },
});
