
import { defineComponent } from 'vue';

export default defineComponent({
  title: 'Pagination',
  props: {
    page: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
      required: true,
    },
  },
  computed: {
    pages(): number[] {
      return Array(this.size).fill(null).map((u, count) => count + 1);
    },
    isEnded(): boolean {
      return this.page + 5 >= this.size;
    },
    left(): number[] {
      const half = Math.round(this.pages.length / 2);

      if (half < 3) {
        return this.pages.slice().splice(0, half);
      }

      return this.contructPages(this.isEnded ? (this.size - 5) : this.page, 0, 3);
    },
    right(): number[] {
      const half = Math.round(this.pages.length / 2);

      if (half < 3) {
        const tempRight = this.pages.slice().splice(-half);
        let isExists = false;
        for (let index = 0; index < tempRight.length; index += 1) {
          const element = tempRight[index];
          isExists = this.left.includes(element);
          if (isExists) {
            break;
          }
        }
        return this.pages.slice().splice(isExists ? -(half - 1) : half);
      }

      return this.contructPages(this.isEnded ? (this.size - 5) : this.page, 3, 6);
    },
  },
  emits: ['update:page'],
  methods: {
    changePage(page: string | number): void {
      if (page === 'prev') {
        if (this.page === 1) return;
        this.$emit('update:page', this.page - 1);
        return;
      }

      if (page === 'next') {
        if (this.page === this.size) return;
        this.$emit('update:page', this.page + 1);
        return;
      }

      this.$emit('update:page', page);
    },
    contructPages(mutator: number, start: number, frequency: number): number[] {
      const array: number[] = [];
      for (let index = start; index < frequency; index += 1) {
        if (mutator + index > 0) {
          array.push(mutator + index);
        }
      }
      return array;
    },
  },
});
