
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UiButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
