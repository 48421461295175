
import { defineComponent } from 'vue';
import { useMeta } from 'vue-meta';

export default defineComponent({
  name: 'VueMeta',
  props: {
    metaInfo: {
      type: Object as any,
    },
  },
  created() {
    useMeta(this.metaInfo);
  },
});
