
import { defineComponent } from 'vue';
import VueMeta from '@/components/meta/VueMeta.vue';
import store from '@/store';
import NavigationBar from '@/components/navigation/NavigationBar.vue';
import NavigationBarMobile from '@/components/navigation/NavigationBarMobile.vue';
import SubNavigationBar from '@/components/navigation/SubNavigationBar.vue';
import Sponsors from '@/components/ui/Sponsors.vue';
import SponsorsMobile from '@/components/ui/SponsorsMobile.vue';
import UiFooter from '@/components/ui/UiFooter.vue';
import AppUpdateModal from '@/components/ui/AppUpdateModal.vue';
import AuthService from '@/services/AuthService';

export default defineComponent({
  name: 'App',
  data: () => ({
    mobileViews: ['Home', 'Inventory', 'Product', 'CompanyShow', '404'],
  }),
  computed: {
    token() {
      return store.getters.token;
    },
    isMainNavigationVisible() {
      return store.getters.mainNavigation;
    },
    isSubNavigationVisible() {
      return store.getters.subNavigation;
    },
    modalOpen() {
      return store.getters.modalOpen;
    },
    showMobileNavigation() {
      return store.getters.showMobileNavigation;
    },
    isMobileView(): boolean {
      return this.mobileViews.includes(this.$route.name as string);
    },
    metaTitle() {
      return process.env.VUE_APP_TITLE;
    },
    metaDescription() {
      return process.env.VUE_APP_GENERAL_META_DESCRIPTION;
    },
    metaInfo() {
      return {
        title: this.metaTitle,
        description: this.metaDescription,
      };
    },
  },
  components: {
    VueMeta,
    NavigationBar,
    NavigationBarMobile,
    SubNavigationBar,
    Sponsors,
    SponsorsMobile,
    UiFooter,
    AppUpdateModal,
  },
  watch: {
    token: {
      immediate: true,
      async handler(val) {
        if (!val) return;

        try {
          const response = await AuthService.getUser();

          store.commit('SET_USER', response);
        } catch (error: any) {
          if (
            error?.response?.status === 401
            || error?.response?.status === 403
          ) {
            store.dispatch('logout');

            this.$router.push({ name: 'Home' });
          }
        }
      },
    },
    modalOpen: {
      immediate: true,
      handler(val) {
        if (val) {
          document.body.classList.add('modal-open');
        } else {
          document.body.classList.remove('modal-open');
        }
      },
    },
    showMobileNavigation: {
      immediate: true,
      handler(val) {
        if (val) {
          document.body.classList.add('mobile-nav-open');
        } else {
          document.body.classList.remove('mobile-nav-open');
        }
      },
    },
  },
  async mounted() {
    this.$nextTick(() => {
      const events = ['load', 'resize'];

      events.forEach((type: string) => {
        window.addEventListener(type, () => {
          const state = window.innerWidth < 1024;

          store.commit('SET_IS_MOBILE', state);
          store.commit('SET_IS_SCREEN_WIDTH', window.innerWidth);
        });
      });
    });
  },
});
