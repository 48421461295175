
import { defineComponent } from 'vue';
import store from '@/store';
import CategoryService from '@/services/CategoryService';
import ATCDropdown from '@/components/modals/ATCRegister/ATCDropdown.vue';
import DrugList from '@/components/modals/ATCRegister/DrugList.vue';
import UiOverlay from '../form/UiOverlay.vue';
import UiScroll from '../form/UiScroll.vue';
import UiSwitch from '../form/UiSwitch.vue';

export default defineComponent({
  name: 'ATCRegister',
  data: () => ({
    modal: false,
    categoryLists: [],
    selectedCategories: [] as Array<any>,
    usedinBranchOnly: true,
    searchTimeoutId: 0,
    atc: {},
    atcList: [],
    atcSearchInput: '',
  }),
  computed: {
    sectionCount() {
      if (this.screenWidth < 1024) {
        return 1;
      }

      if (this.screenWidth < 1366) {
        return 2;
      }

      return 3;
    },
    atcSections(): any {
      return this.categoryLists?.slice(
        Math.max(this.categoryLists?.length - this.sectionCount, 0),
      );
    },
    allCategories(): any {
      return this.categoryLists?.flat();
    },
    selectedCategoriesFull(): any {
      return this.selectedCategories
        ?.map((el: any) => this.allCategories
          ?.find((c: any) => el === c.code)) as any;
    },
    screenWidth() {
      return store.getters.screenWidth;
    },
    itemCount(): any {
      return this.selectedCategoriesFull[
        this.selectedCategoriesFull?.length - 1
      ]?.branch_item_count;
    },
    activeCode(): any {
      return this.selectedCategories[this.selectedCategories?.length - 1];
    },
  },
  components: {
    ATCDropdown,
    DrugList,
    UiOverlay,
    UiScroll,
    UiSwitch,
  },
  async mounted() {
    const params = {
      category_types: ['atc'],
      used_in_branch_only: this.usedinBranchOnly,
      take: 50,
    };

    const response = await CategoryService.search(params);

    this.atcList = response.data;
  },
  methods: {
    openModal(code?: string) {
      this.modal = true;
      this.usedinBranchOnly = true;
      this.getAtcTree(code);
    },
    async back() {
      const code = this.selectedCategories[this.selectedCategories.length - 2];

      this.getAtcTree(code);
    },
    async getAtcTree(code?: string) {
      try {
        const response = await CategoryService.atcTree({ code });
        (this.$refs['ui-scroll'] as any).scrollTop();

        this.categoryLists = response?.categories;
        this.selectedCategories = response?.selected_categories;
      } catch (error: any) {
        this.modal = false;

        if (
          error?.response?.status === 422
          || error?.response?.status === 404
          || error?.response?.status === 409
        ) {
          this.$toast.show(error.response.data?.error?.message, 3000);
        } else {
          this.$toast.show('Server error', 3000);
        }
      }
    },
    isActive(code?: string) {
      return this.selectedCategories.includes(code);
    },
    getParentATCId(section?: any): any {
      const sectionIndex = this.categoryLists
        ?.findIndex((el: any) => el.length === section.length && el
          .every((value: any, index: any) => value === section[index])) as any;

      const parentSection = this.categoryLists[sectionIndex - 1] as any;

      const parentCategory = parentSection?.find(
        (el: any) => this.selectedCategories?.includes(el.code),
      );

      return parentCategory?.id;
    },
  },
  watch: {
    async atc(val) {
      if (!val.code) return;

      this.getAtcTree(val.code);
      this.atc = {};
    },
    async usedinBranchOnly() {
      const params = {
        category_types: ['atc'],
        take: 50,
        used_in_branch_only: this.usedinBranchOnly,
      };

      const response = await CategoryService.search(params);

      this.atcList = response.data;

      this.getAtcTree();

      this.selectedCategories = [];
    },
    async atcSearchInput(val) {
      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        const params = {
          title: val,
          category_types: ['atc'],
          take: 50,
          used_in_branch_only: this.usedinBranchOnly,
        };

        const response = await CategoryService.search(params);

        this.atcList = response.data;
      }, 300);
    },
  },
});
