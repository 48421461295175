import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slider-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-slider", { prev: _ctx.showPrev, next: _ctx.showNext }]),
    ref: "ui-slider"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "slider-items",
        style: _normalizeStyle(_ctx.transalteStyle)
      }, [
        _renderSlot(_ctx.$slots, "items")
      ], 4)
    ]),
    (_ctx.showPrev)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          class: "prev",
          icon: ['fas', 'chevron-left'],
          onClick: _ctx.sliderPrev
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.showNext)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          class: "next",
          icon: ['fas', 'chevron-right'],
          onClick: _ctx.sliderNext
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 2))
}