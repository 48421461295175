import { vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f2420e82"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    onBeforeEnter: _ctx.leave,
    onEnter: _ctx.enter,
    onBeforeLeave: _ctx.enter,
    onLeave: _ctx.leave
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        ref: "toast",
        class: "toast",
        style: _normalizeStyle(_ctx.style),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCloseHandler && _ctx.onCloseHandler(...args))),
        innerHTML: _ctx.message
      }, null, 12, _hoisted_1), [
        [_vShow, _ctx.show]
      ])
    ]),
    _: 1
  }, 8, ["onBeforeEnter", "onEnter", "onBeforeLeave", "onLeave"]))
}