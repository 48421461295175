
import { defineComponent } from 'vue';
import store from '@/store';
import PreLoader from '@/components/PreLoader/PreLoader.vue';

export default defineComponent({
  name: 'AppUpdateModal',
  components: {
    PreLoader,
  },
  computed: {
    updateAvailable() {
      return store.getters.updateAvailable;
    },
  },
  watch: {
    updateAvailable: {
      immediate: true,
      handler(val) {
        store.commit('SET_MODAL_OPEN', val);
      },
    },
  },
});
