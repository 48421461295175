
import { defineComponent } from 'vue';
import UiScroll from '@/components/form/UiScroll.vue';
import UiSlider from '@/components/slider/UiSlider.vue';

export default defineComponent({
  name: 'Search',
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    searchInput: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
    },
  },
  data: () => ({
    focus: false,
  }),
  computed: {
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
    input: {
      get(): string {
        return this.searchInput;
      },
      set(val: string) {
        this.$emit('update:search-input', val);
        // this.$emit('input', val);
      },
    },
  },
  components: {
    UiScroll,
    UiSlider,
  },
  methods: {
    toggleItem(model: any) {
      const isSelected = this.model.some((el: any) => el.id === model.id);

      if (isSelected) {
        this.removeItem(model);
        return;
      }

      this.clearFocus();
      this.$emit('item-add', model);
      this.input = '';
    },
    removeItem(model: any) {
      this.clearFocus();
      this.model = this.model.filter((el: any) => el.id !== model.id);
      this.$emit('item-remove', model);
    },
    isSelected(itemId: string) {
      return this.model.some((el: any) => el.id === itemId);
    },
    clearFocus() {
      this.focus = false;
      (this.$refs['search-input'] as any).blur();
    },
  },
});
