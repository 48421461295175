import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-53542b46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-desktop" }
const _hoisted_2 = { class: "max-content main-navigation" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "navigation-bar" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "link-sub-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_clickable = _resolveComponent("clickable")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "company-logo"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: require('@/assets/images/logo.png'),
            alt: "Logo"
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuLinks, (link, i) => {
          return (_openBlock(), _createElementBlock("a", {
            key: `menu-item-${i}`,
            href: link.url,
            class: "navigation-link"
          }, _toDisplayString(link.title), 9, _hoisted_5))
        }), 128))
      ]),
      _createElementVNode("div", {
        class: "profile-logo",
        onMouseover: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseOver && _ctx.onMouseOver(...args))),
        onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args)))
      }, [
        _createVNode(_component_clickable, {
          class: _normalizeClass(`${_ctx.token ? 'loggedIn' : 'loggedOut'}`),
          tag: "span",
          onClick: _ctx.onClickProfileHandler
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              class: "profile-icon",
              icon: ['fal', 'user']
            })
          ]),
          _: 1
        }, 8, ["class", "onClick"]),
        _createVNode(_Transition, { name: "link-animation" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_6, [
              (_ctx.hasRole('super-admin') || _ctx.hasRole('admin'))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "sub-link",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.editAccount && _ctx.editAccount(...args)))
                  }, " Edit account "))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "sub-link",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLogOut && _ctx.onLogOut(...args)))
              }, "Log out")
            ], 512), [
              [_vShow, _ctx.token && _ctx.subMenu]
            ])
          ]),
          _: 1
        })
      ], 32)
    ])
  ]))
}