import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { User } from '@/models/auth';

const store = createStore({
  state: {
    token: '',
    user: {} as User,
    mainNavigation: true,
    subNavigation: true,
    showFilters: false,
    mode: 'blocks',
    isMobile: false,
    screenWidth: '',
    modalOpen: false,
    updateAvailable: false,
    filters: '',
    showMobileNavigation: false,
  },
  getters: {
    token: (state) => state.token,
    user: (state) => state.user,
    mainNavigation: (state) => state.mainNavigation,
    subNavigation: (state) => state.subNavigation,
    showFilters: (state) => state.showFilters,
    mode: (state) => state.mode,
    isMobile: (state) => state.isMobile,
    screenWidth: (state) => state.screenWidth,
    modalOpen: (state) => state.modalOpen,
    updateAvailable: (state) => state.updateAvailable,
    filters: (state) => state.filters,
    showMobileNavigation: (state) => state.showMobileNavigation,
  },
  mutations: {
    SET_TOKEN: (state, payload) => {
      state.token = payload;
    },
    SET_USER: (state, payload) => {
      state.user = payload;
    },
    SET_MAIN_NAVIGATION: (state, payload) => {
      state.mainNavigation = payload;
    },
    SET_SUB_NAVIGATION: (state, payload) => {
      state.subNavigation = payload;
    },
    SET_SHOW_FILTERS: (state, payload) => {
      state.showFilters = payload;
    },
    SET_MODE: (state, payload) => {
      state.mode = payload;
    },
    SET_IS_MOBILE: (state, payload) => {
      state.isMobile = payload;
    },
    SET_IS_SCREEN_WIDTH: (state, payload) => {
      state.screenWidth = payload;
    },
    SET_MODAL_OPEN: (state, payload) => {
      state.modalOpen = payload;
    },
    SET_LOGOUT: (state) => {
      state.token = '';
      state.user = {} as User;
    },
    SET_UPDATE_AVAILABLE: (state, payload) => {
      state.updateAvailable = payload;
    },
    SET_FILTERS: (state, payload) => {
      state.filters = payload;
    },
    SET_SHOW_MOBILE_NAVIGATION: (state, payload) => {
      state.showMobileNavigation = payload;
    },
  },
  actions: {
    logout: async ({ commit }) => {
      commit('SET_TOKEN', '');
      commit('SET_USER', {});
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      key: 'VINORDIC',
      paths: ['token', 'mode', 'user.roles', 'filters'],
    }),
  ],
});

export default store;
