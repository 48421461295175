
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Search',
  props: {
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  data: () => ({
    focus: false,
  }),
  computed: {
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
  },
});
