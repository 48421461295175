import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slick-slider" }
const _hoisted_2 = { class: "slick" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "items")
    ]),
    _createVNode(_component_font_awesome_icon, {
      class: "prev",
      icon: ['fas', 'chevron-left']
    }),
    _createVNode(_component_font_awesome_icon, {
      class: "next",
      icon: ['fas', 'chevron-right']
    })
  ]))
}