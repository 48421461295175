
import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'UiFooter',
  data: () => ({
    isLoading: false,
    contacts: {} as any,
  }),
  async mounted() {
    this.isLoading = true;

    const response = await axios({
      url: process.env.VUE_APP_WP_FOOTER_API_URL,
      method: 'GET',
      responseType: 'json',
    });

    const { acf } = response.data;

    this.contacts = {
      street: acf.contacts_street,
      zipcode: acf.contacts_zipcode,
      city: acf.contacts_city,
      googlemapsurl: acf.contacts_googlemapsurl,
      websitetitle: acf.contacts_websitetitle,
      websiteurl: acf.contacts_websiteurl,
      phone: acf.contacts_phone,
      email: acf.contacts_email,
    };

    this.isLoading = false;
  },
});
