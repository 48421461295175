
import { defineComponent } from 'vue';
import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default defineComponent({
  name: 'SlickSlider',
  mounted() {
    this.$nextTick(() => {
      ($('.slick') as any).slick({
        infinite: true,
        slidesToShow: 11,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        prevArrow: $('.prev'),
        nextArrow: $('.next'),
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        speed: 500,
        // responsive: [
        //   {
        //     breakpoint: 1023,
        //     settings: {
        //       centerMode: true,
        //       slidesToShow: 5,
        //     },
        //   },
        //   {
        //     breakpoint: 500,
        //     settings: {
        //       centerMode: true,
        //       slidesToShow: 2,
        //     },
        //   },
        // ],
      });
    });
  },
});
