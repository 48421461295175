
import { defineComponent } from 'vue';
import store from '@/store';
import axios from 'axios';

export default defineComponent({
  name: 'NavigationBarMobile',
  data: () => ({
    menuItems: [],
  }),
  computed: {
    showMobileNavigation: {
      get() {
        return store.getters.showMobileNavigation;
      },
      set(newValue: boolean) {
        store.commit('SET_SHOW_MOBILE_NAVIGATION', newValue);
      },
    } as any,
    showFilters: {
      get() {
        return store.getters.showFilters && this.showCategoryToggler;
      },
      set(newValue: string) {
        store.commit('SET_SHOW_FILTERS', newValue);
      },
    } as any,
    showCategoryToggler(): boolean {
      return this.$route.name === 'Inventory' || this.$route.name === 'Home';
    },
  },
  async mounted() {
    const response = await axios({
      url: process.env.VUE_APP_WP_MENU_API_URL,
      method: 'GET',
      responseType: 'json',
    });

    this.menuItems = response.data.items.sort((a: any, b: any) => a.menu_order - b.menu_order);
  },
  methods: {
    openUrl(url: string) {
      window.open(url, '_self');
    },
  },
});
