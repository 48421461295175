import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/filters.png'
import _imports_1 from '@/assets/images/menu.png'
import _imports_2 from '@/assets/images/menu_red.png'


const _withScopeId = (n: any) => (_pushScopeId("data-v-1b92ca6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toggle-filters" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "toggle-navigation" }
const _hoisted_4 = { class: "navigation-menu" }
const _hoisted_5 = { class: "items" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["navigation-mobile view-mobile", { fixed: _ctx.showMobileNavigation }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["navigation-bar", { transparent: !_ctx.showMobileNavigation && _ctx.showFilters }])
    }, [
      _createElementVNode("span", _hoisted_1, [
        (_ctx.showCategoryToggler && !_ctx.showMobileNavigation)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _withDirectives(_createElementVNode("img", {
                src: _imports_0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFilters = true))
              }, null, 512), [
                [_vShow, !_ctx.showFilters]
              ]),
              _withDirectives(_createVNode(_component_font_awesome_icon, {
                icon: ['fal', 'times'],
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFilters = false))
              }, null, 512), [
                [_vShow, _ctx.showFilters]
              ])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("img", {
        class: "logo",
        src: require('@/assets/images/logo.png'),
        alt: "Logo",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'Home' })))
      }, null, 8, _hoisted_2),
      _createElementVNode("span", _hoisted_3, [
        _withDirectives(_createElementVNode("img", {
          src: _imports_1,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showMobileNavigation = true))
        }, null, 512), [
          [_vShow, !_ctx.showMobileNavigation]
        ]),
        _withDirectives(_createElementVNode("img", {
          src: _imports_2,
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showMobileNavigation = true))
        }, null, 512), [
          [_vShow, !_ctx.showMobileNavigation && _ctx.showFilters]
        ]),
        _withDirectives(_createVNode(_component_font_awesome_icon, {
          icon: ['fal', 'times'],
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showMobileNavigation = false))
        }, null, 512), [
          [_vShow, _ctx.showMobileNavigation]
        ])
      ])
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `item-${i}`,
            class: "item",
            onClick: ($event: any) => (_ctx.openUrl(item.url))
          }, _toDisplayString(item.title), 9, _hoisted_6))
        }), 128))
      ])
    ], 512), [
      [_vShow, _ctx.showMobileNavigation]
    ])
  ], 2))
}