import axios, { AxiosInstance } from 'axios';
import store from '@/store';

const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: {
    'Content-type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

httpClient.interceptors.response.use((response) => response.data);

httpClient.interceptors.request.use(
  (configuration) => {
    const config = configuration;
    if (store.getters.token) {
      config.headers.Authorization = `Bearer ${store.getters.token}`;
    }
    return config;
  },
);

export default httpClient;
