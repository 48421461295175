import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0bd34b30"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "search-filters"
}
const _hoisted_2 = { class: "tag-title" }
const _hoisted_3 = { class: "search-box" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = {
  key: 1,
  class: "search-dropdown"
}
const _hoisted_6 = ["onMousedown", "title"]
const _hoisted_7 = { class: "title" }
const _hoisted_8 = {
  key: 0,
  class: "type"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ui_slider = _resolveComponent("ui-slider")!
  const _component_ui_scroll = _resolveComponent("ui-scroll")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["search", { focus: _ctx.focus, 'no-items': !_ctx.items.length }])
  }, [
    (_ctx.model && _ctx.model.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ui_slider, {
            id: "filters-slider",
            class: "filters-slider",
            scrollToEnd: true
          }, {
            items: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (item, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `tag-${i}`,
                  class: "tag"
                }, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(item?.title), 1),
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fal', 'times'],
                    onClick: ($event: any) => (_ctx.removeItem(item)),
                    class: "remove-icon"
                  }, null, 8, ["onClick"])
                ]))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
        placeholder: _ctx.placeholder,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focus = true)),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focus = false)),
        class: "search-input",
        ref: "search-input"
      }, null, 40, _hoisted_4), [
        [_vModelText, _ctx.input]
      ]),
      _createVNode(_component_font_awesome_icon, {
        class: "search-icon",
        icon: ['fal', 'search']
      })
    ]),
    (_ctx.focus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_ui_scroll, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `item-${i}`,
                  class: _normalizeClass(["dropdown-item", { selected: _ctx.isSelected(item.id) }]),
                  onMousedown: _withModifiers(($event: any) => (_ctx.toggleItem(item)), ["prevent"]),
                  title: item.title
                }, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(item.title), 1),
                  (item.type_title)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(item.type_title), 1))
                    : _createCommentVNode("", true)
                ], 42, _hoisted_6))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}