import store from '@/store';

export default {
  methods: {
    hasRole(role: string) {
      return store.getters?.user?.roles
        ?.map((r: any) => r.name)?.includes(role);
    },
    isOwner(code: string) {
      return store.getters?.user?.companies
        ?.map((c: any) => c.short_code)?.includes(code);
    },
  },
};
