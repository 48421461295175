import { Component } from 'vue';

export const TYPE_CATEGORY = 'category';
export const TYPE_ATC = 'atc';
export const TYPE_SIMPLE = 'simple';

export type Tag = string | Component;

export interface AccordianInterface {
  title: string | 'Title',
  autoScroll: boolean,
}

export interface ClickableInterface {
  tag: Tag,
  default?: string | Component,
}

export interface UiButtonInterface {
  default?: string,
  type?: string,
}

export interface TagTypeClassInterface {
  [key: string]: string
}

export const TYPE_CLASS: TagTypeClassInterface = {
  [TYPE_SIMPLE]: '',
  [TYPE_ATC]: 'button-atc',
  [TYPE_CATEGORY]: 'button-category',
};
