
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UiCheckBox',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    trueLabel: {
      type: String,
      default: 'Ja',
    },
    falseLabel: {
      type: String,
      default: 'Nej',
    },
  },
  computed: {
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
  },
});
